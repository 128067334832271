import { useNavigate } from 'react-router-dom';
import NavBar from '../component/navbar/navbar';

const Terms = () => {
  const navigate = useNavigate();
  return (
    <div>
      <header>
        <NavBar isComingSoon={true} />
      </header>
      <main>
        <section className='privacy_policy_container'>
          <p
            onClick={() => navigate(-1)}
            style={{
              padding: '1rem 2rem',
              marginBottom: '2rem',
              border: '1px solid #1d1d1d',
              width: 'fit-content',
              cursor: 'pointer',
            }}
          >
            Go Back
          </p>

          <h1 className='privacy_policy-title'>LOOOTY TERMS OF USE</h1>
          <div className='privacy_policy-body'>
            <span className='privacy_policy-section-title'>Introduction</span>
            <div className='privacy_policy-paragraph'>
              Loooty is an Afro-centric Art Asset marketplace owned and managed
              by Loooty Digital Marketplace Limited (Loooty) that enables
              creators to display and sell their 2D and 3D digital artwork,
              while also providing buyers with a diverse selection of
              high-quality art pieces in various styles and formats. It allows
              for various assets, including an Afrocentric base, but is open to
              assets from all over the world, 2D Assets, UI Assets, Game UI
              Assets, 3D Assets, Game-ready, VR, AR, Plugins, Tools and Sound.
            </div>
            <div className='privacy_policy-paragraph'>
              These terms (Terms) govern how the Marketplace operates, how
              Assets may be licensed via the Marketplace, how such licences may
              be sold and purchased and how the relevant Assets may be used.
              These Marketplace Terms apply in addition to the Privacy Policy,
              Creator onboarding policy and our Cookie Policy.
            </div>
            <div className='privacy_policy-paragraph'>
              In this Terms, reference to “we”, “us”, “our”, or “Company” refers
              to Loooty Digital Marketplace Limited, its successors-in-title and
              assigns while “you” or “your” refers to you and every person who
              subscribes to, accesses, uses or authorizes the access to or use
              of the marketplace. Also, reference to “Creators’’ includes anyone
              who offers products for sale on this marketplace, “Purchaser”
              refers to anyone who seeks to pay for products on this marketplace
              and “User” refers to anyone registered to the site and is able to
              Interact with Store owners and their products(buy, report and
              review) and can socialise with other Users in the Marketplace.
              Please note that by using the Marketplace (whether as a Purchaser,
              User or Creator), you agree to be bound by these Marketplace Terms
              and the other documents expressly referred to in it. However, if
              you do not agree to these policies, please cease using or
              accessing our marketplace, and its services.
            </div>
            <div className='privacy_policy-paragraph'>
              You also agree that these Terms are not subject to any terms and
              conditions of any other marketplace or platform, whether or not
              connected to us or the marketplace. Likewise, the Terms are not
              applicable to any other marketplace (not being the Loooty
              marketplace) however connected to Loooty; and the application of
              any information you access on this marketplace is restricted to
              it.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>Age Restriction:</span>{' '}
              To use this Marketplace, you must have attained the age of 18
              years or the age of majority as required in your jurisdiction.
              Individuals under the age of 18, or the applicable age of majority
              in their jurisdiction, may utilize our marketplace only under the
              supervision of a parent or legal guardian, under such person’s
              account. Regardless, all Data which we may process shall be in
              accordance with these Terms, our Privacy Policy, and other
              applicable laws.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>Your Privacy:</span> At
              Loooty, we are committed to managing your Personal Information in
              line with global industry best practices. You can read our Privacy
              Policy to understand how we protect and use your information-
              Loooty privacy policy.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>
                Changing, modifying, or amending the Terms:
              </span>{' '}
              We reserve the right to modify, suspend, or terminate the Digital
              Marketplace or any part thereof at any time without notice. We may
              update these Terms of Use from time to time, and the updated
              version will be effective upon posting on the Digital Marketplace.
              When this is done, we shall notify you of such changes via a popup
              notification on our home page or an email. Your continued use of
              the platform after the changes constitutes your acceptance of the
              updated Terms of Use. If you object to any of the changes to the
              Terms, please cease using or accessing our marketplace and
              uninstall immediately.
            </div>
            <span className='privacy_policy-section-title'>Definitions</span>
            <div className='privacy_policy-paragraph'>
              In this Terms, the following definitions apply (in addition to the
              other definitions herein):
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Asset(s)”-</span> Means
              any work, information, data, software, executable code, image,
              drawing, animation, audio content or video content in any digital
              medium or form including (but not limited to) 2D Assets, UI
              Assets, Game UI Assets, 3D Assets, Game-ready, VR, AR, Plugins,
              Tools and Sound.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Creator”-</span> Refers
              to the individual or entity who has produced, designed, or
              developed the Artwork available on the Loooty Marketplace and
              offers to grant a Licence of the Asset to a Purchaser by way of
              sub-license. The Creator of Artwork holds the intellectual
              property rights, including copyrights, in the original artwork.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Derivative Work”-</span>{' '}
              Refers to any new work, creation, or adaptation that is based on
              or derived from a Licensed Asset protected by intellectual
              property rights (such as copyrights), which is recast, transformed
              or adapted in another form.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>"Licence(s)"-</span>{' '}
              Refers to the permission granted by the Creator by way of
              sub-license to the Purchaser on a non-exclusive perpetual licence
              for authorized use as provided in this Terms of Use.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Licensed Asset”-</span>{' '}
              Means an Asset in respect of which a Licence has been sold by a
              Creator and purchased by a Purchaser via the Marketplace.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>"Media Product"-</span>{' '}
              Means any digital and/or media product, creation or platform of a
              Purchaser including (but not limited to) software, applications,
              video content, audio content, documents and websites.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Members”-</span> Refers
              to Users, Purchasers and Creators on the Marketplace.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>
                "Monetized Media Product"-
              </span>{' '}
              Means a Media Product which, in addition to any original sale
              price of the Media Product, is capable of producing further
              income, profits, gains and any other financial consideration,
              value, receipt or measure for any party by any means whatsoever,
              including (but not limited to) via in-app purchase facilities or
              advertising.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>
                "Non-Monetized Media Product"-
              </span>{' '}
              Means a Media Product in relation to which, other than the
              original sale price of the Media Product, no party is capable of
              receiving any further income, profits, gains and any other
              financial consideration, value, receipt or measure by any means
              whatsoever, including (but not limited to) via in-app purchase
              facilities or advertising.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-outline'>“Purchaser”-</span> means
              a Member who purchases a Licence of an Asset by way of a
              sub-licence from the Creator via the Marketplace.
            </div>
            <h1 className='privacy_policy-title'>Licence to use website</h1>
            Subject to the other provisions of these terms of use, you may:
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              View pages from our site in a web browser;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Download pages from our site for caching in a web browser;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Stream audio and video files from our site;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Purchase and sell Assets via our site in accordance with the
              Marketplace Terms.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Except as expressly permitted by clause 3(a) or the other
              provisions of these terms of use, you must not download any
              material from our site or save any such material to your computer.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Unless you own or control the relevant rights in the material, you
              must not:
              <div className='privacy_policy-sub-section'>
                Republish material from our site (including republication on
                another website);
              </div>
              <div className='privacy_policy-sub-section'>
                Sell, rent or sub-license material from our site;
              </div>
              <div className='privacy_policy-sub-section'>
                Show any material from our site in public;
              </div>
              <div className='privacy_policy-sub-section'>
                Exploit material from our site for a commercial purposes; or
              </div>
              <div className='privacy_policy-sub-section'>
                Redistribute material from our site, except as may be permitted
                in accordance with this Terms.
              </div>
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We reserve the right to restrict access to areas of our site, or
              indeed our whole site, at our discretion. You must not circumvent
              or bypass, or attempt to circumvent or bypass, any access
              restriction measures on our site.
            </div>
            <span className='privacy_policy-section-title'>
              Account Registration
            </span>
            You may register for an account with our marketplace by completing
            and following the steps in the Registration link. You represent and
            warrant that all information provided in the registration form is
            complete and accurate. If you register an account on our
            marketplace, you will be asked to provide an email address/user ID
            and password and you agree to:
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              If you register for an account with our site, you will be asked to
              choose a username and password.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Your username must not be liable to mislead and must comply with
              the content rules in these terms of use; you must not use your
              account or username for or in connection with the impersonation of
              any person.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You must keep your password confidential.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You must notify us in writing immediately if you become aware of
              any disclosure of your password.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You are responsible for any activity on our site arising out of
              any failure to keep your password confidential and may be held
              liable for any losses arising out of such a failure.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Only Members that have registered an account and expressly agreed
              to these terms will be permitted to buy and sell Assets in our
              Marketplace.
            </div>
            <span className='privacy_policy-section-title'>
              User Verification
            </span>
            Users may opt to join our verification system. The Loooty
            verification system is put in place to maintain a secure and trusted
            environment for all users of our Marketplace. When you are verified
            you have more eyes on your Posts and engagements. By opting for
            Loooty verification, you agree to provide accurate and up-to-date
            information as may be required for user verification purposes. We
            reserve the right to verify user information through various means,
            including but not limited to requesting identity verification
            documents, conducting background checks, and utilizing third-party
            verification services. Providing false or misleading information in
            the verification process may result in the suspension or termination
            of your account.
            <span className='privacy_policy-section-title'>
              The Marketplace
            </span>
            Members may, in accordance with this Terms OF Use-
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Sell Licences in respect of their Assets to Purchasers on the
              Marketplace by way of sub-license;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Purchase Licences by way of sub-licence from the Creators on the
              Marketplace; and
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Browse or access the Marketplace.
            </div>
            <span className='privacy_policy-section-title'>License</span>A
            "Licence" refers to the permission granted by the Creator by way of
            sub-license to the Purchaser on a non-exclusive perpetual licence
            for authorized use as provided in this Terms of Use. The Purchaser
            may be permitted to-
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              use the Licensed Asset to create Derivative Works
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              use the Licensed Asset and any Derivative Works as part of both
              Non-Monetized Media Products and Monetized Media Products, either
              used for the Purchaser's own personal use; and/or used for the
              Purchaser’s commercial use in which case it may be distributed,
              sold and supplied by the Purchaser for any fee that the Purchaser
              may determine, with no restriction on the number of projects the
              Licensed Asset may be used in.
            </div>
            A Licence does not permit the Purchaser to:
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Use the Licensed Asset or Derivative Works in a logo, trademark or
              service mark;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Use, sell, share, transfer, give away, sublicense or redistribute
              the Licensed Asset or Derivate Works other than as part of the
              relevant Media Product; or
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Allow the user of the Media Product to extract the Licensed Asset
              or Derivative Works and use them outside of the relevant Media
              Product.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Permit the user of the Non-Monetized Media Product or Monetized
              Media Product to extract the Licensed Asset or Derivative Works
              and use them outside of the relevant Non-Monetized Media Product
              or Monetized Media Product.
            </div>
            <span className='privacy_policy-section-title'>
              Purchase of licences
            </span>
            The Licences dictate how the Assets may be used by the Purchaser.
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Loooty is a party to Licencing transactions purely for the purpose
              of providing the Loooty Platform where for the display of
              artworks, and to receive payments on behalf of the Creators.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The prices of the Licences ("Purchase Prices") will be as quoted
              on the Marketplace from time to time.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The Marketplace contains a large number of Assets. The Purchaser
              and Creator acknowledge and agree that despite Loooty's reasonable
              precautions, Purchase Prices may be listed at an incorrect price
              or with incorrect information due to a typographical error or
              similar oversight. In these circumstances, we reserve the right to
              cancel or reverse a transaction, even after an order has been
              confirmed and payment has been processed. If a transaction is
              cancelled, we will arrange for any payment to be credited or
              refunded.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              By virtue of submitting an order for a Licence via the
              Marketplace, the Purchaser shall be deemed to have consented to be
              supplied the applicable Licensed Asset for download subject to its
              availability.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Neither the Creator nor the Purchaser may cancel an order for a
              Licence once the order has been submitted via the Marketplace.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Loooty does not give any undertaking as to the continued
              availability of Assets offered for Licence via the Marketplace nor
              its quality. Loooty merely provides the Platform and verifies its
              users. Purchasers are advised to make an independent assessment
              before placing an order.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The contracts between the Creator and the Purchaser will only be
              formed when Loooty confirms acceptance of an order.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The Purchaser must pay the Purchase Price in full via the payment
              link.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Once the Purchase Price has been received by Loooty, Loooty will
              send the Purchaser and Creator an e-mail that confirms that the
              Purchase Price has been received ("Purchase Confirmation").
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              After the receipt of the purchase price and the sending of the
              purchase confirmation, Loooty shall withhold payment till the
              purchaser confirms the validity of the purchased item.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>A Licence shall be
              granted from the Creator to the Purchaser by way of sub-licence
              and shall become effective once Loooty issues the Purchase
              Confirmation.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>A Licensed Asset
              may be downloaded by the Purchaser from the Marketplace
              immediately following notification of its availability for
              download by Loooty.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Subject to clauses 6(f), during its availability on the
              Marketplace a Licensed Asset may be accessed and downloaded by the
              Purchaser from their email, or the Loooty Marketplace.
            </div>
            <span className='privacy_policy-section-title'>
              Ownership of assets, derivative works and media products.
            </span>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Any and all intellectual property rights in the Asset shall be
              owned by the Creator.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Any and all intellectual property rights in Derivative Works shall
              be owned by the Creator. To the extent that any such intellectual
              property rights automatically vest in the Purchaser, then in
              consideration of the Licence granted to the Purchaser to create
              Derivative Works (which the Purchase hereby acknowledges is
              adequate and sufficient consideration), the Purchaser hereby
              assigns by way of present and future assignment, any and all such
              rights to the Creator. The Purchaser shall execute and deliver
              such documents and perform such acts as may be required for the
              purpose of giving evidence of and/or full effect to such
              assignment.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Subject to clauses 6(a) and 6(b), any and all intellectual
              property rights in the Media Product shall be owned by the
              Purchaser.
            </div>
            <span className='privacy_policy-section-title'>
              Limitation of Warranties
            </span>
            We do not warrant or represent:
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The completeness or accuracy of the information published on our
              site;
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              That the material on our site is up to date; or
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              That our site or any service on our site will remain available.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We reserve the right to suspend, discontinue or alter any or all
              of our site services, and to stop publishing our site, at any time
              in our sole discretion without notice or explanation; and save to
              the extent expressly provided otherwise in these terms of use, you
              will not be entitled to any compensation or other payment upon the
              discontinuance or alteration of any website services, or if we
              stop publishing our site.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You agree that we will not be liable to you for any loss that may
              be incurred by any interruption to or suspension of service.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              To the maximum extent permitted by applicable law and subject to
              clause 9, we exclude all representations and warranties relating
              to the subject matter of these terms of use, our site and the use
              of our site.
            </div>
            <span className='privacy_policy-section-title'>
              Limitation of liability
            </span>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We shall not be liable for any special, incidental, indirect or
              consequential damages of any kind in connection with this
              agreement, even if we had been informed in advance of the
              possibility of such damages. Our aggregate liability to you, any
              individual or any third party in any matter arising here from,
              related or connected to our platform is at the company’s sole
              discretion.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              To the extent that any portions of our site and any information
              and services on our site are provided free of charge, we will not
              be liable for any loss or damage of any nature. We will not be
              liable to you in respect of any losses arising out of any event or
              events beyond our reasonable control. We will not be liable to you
              in respect of any business losses, including (without limitation)
              loss of or damage to profits, income, revenue, use, production,
              anticipated savings, business, contracts, commercial opportunities
              or goodwill. We will not be liable to you in respect of any loss
              or corruption of any data, database or software or in respect of
              any special, indirect or consequential loss or damage.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You accept that we have an interest in limiting the personal
              liability of our officers and employees and, having regard to that
              interest, you acknowledge that we are a limited liability entity;
              you agree that you will not bring any claim personally against our
              officers or employees in respect of any losses you suffer in
              connection with our site or these terms of use (this will not, of
              course, limit or exclude the liability of the limited liability
              entity itself for the acts and omissions of our officers and
              employees).
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Where our site contains links to other sites and resources
              provided by third parties, these links are provided for your
              information only. We have no responsibility or liability for the
              content of other websites, which may or may not be provided by or
              through websites linked to our site, even if they are owned or run
              by affiliates of ours. The inclusion of any link to such websites
              on our site does not imply our endorsement, sponsorship, or
              recommendation of that website.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You accept that each Member shall indemnify Loooty against all
              liabilities, costs, expenses, damages or losses (including any
              direct, indirect or consequential losses, loss of profit, loss of
              reputation and all interest, penalties and legal costs (calculated
              on a full indemnity basis) and all other professional costs and
              expenses) suffered or incurred by Loooty arising out of or in
              connection with Loooty’s exercise of the rights granted to it
              under this Terms of Use.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              You accept that, whilst we take precautions to protect the
              information you transmit to our site, we cannot ensure the
              security of that information
            </div>
            <span className='privacy_policy-section-title'>Data privacy</span>
            Members agree to the processing of their personal data in accordance
            with the terms of Our Privacy policy. We shall process all personal
            data obtained through the marketplace and related services in
            accordance with the terms of our Privacy Policy. The Creators shall
            be directly responsible to Purchasers for any misuse of their
            personal data and We shall bear no liability to Purchasers in
            respect of any misuse by sellers of their personal data.
            <span className='privacy_policy-section-title'>Severability</span>
            If any portion of these Terms of Use is held by any court or
            tribunal to be invalid or unenforceable, either in whole or in part,
            then that part shall be severed from these Terms of Use and shall
            not affect the validity or enforceability of any other part in this
            Terms of Use.
            <span className='privacy_policy-section-title'>
              Rules about your content
            </span>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              In these general terms and conditions, "your content" means:
              <div className='privacy_policy-sub-section'>
                All work and materials (including without limitation digital
                artworks, texts, graphics, images, audio material, video
                material, audio-visual material, scripts, software and files)
                that you submit to our marketplace for storage or publication,
                processing by, or onward transmission; and
              </div>
              <div className='privacy_policy-sub-section'>
                All communications on the marketplace, including product
                reviews, feedback and comments.
              </div>
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Your content, and the use of your content by us in accordance with
              these general terms and conditions, must be accurate, complete and
              truthful.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Your content must be appropriate, civil and accord with generally
              accepted standards of etiquette and behaviours on the internet,
              and must not:
              <div className='privacy_policy-sub-section'>
                Be offensive, depict violence in an explicit, graphic or
                gratuitous manner;
              </div>
              <div className='privacy_policy-sub-section'>
                Be in breach of racial or religious hatred or discrimination
                legislation;
              </div>
              <div className='privacy_policy-sub-section'>
                Be deceptive, fraudulent, threatening, abusive, harassing,
                anti-social, menacing, hateful, discriminatory or inflammatory;
              </div>
              <div className='privacy_policy-sub-section'>
                Cause annoyance, inconvenience or needless anxiety to any
                person; or constitute spam.
              </div>
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The review function in the marketplace may be used to facilitate
              buyer reviews on products. You shall not use the review function
              or any other form of communication to provide inaccurate,
              inauthentic or fake reviews.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              Subject to our Marketplace Terms, any content you upload to our
              site will be considered non-confidential and non-proprietary, and
              we have the right to use, copy, distribute and disclose to third
              parties any such content for any purpose.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We also have the right to disclose your identity to any third
              party who is claiming that any content posted or uploaded by you
              to our site constitutes a violation of their intellectual property
              rights, or of their right to privacy.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We will not be responsible, or liable to any third party, for the
              content or accuracy of any content posted by you or any other user
              of our site.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We have the right to remove any posting you make on our site if,
              in our opinion, your post does not comply with our content
              standards.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              The views expressed by other users on our site do not represent
              our views or values.
            </div>
            <div className='privacy_policy-paragraph'>
              <span className='privacy_policy-bullet'>.</span>
              We may periodically review your content and we reserve the right
              to remove any content at our discretion for any reason whatsoever.
              If you learn of any unlawful material or activity on our
              marketplace or any material or activity that breaches these
              general terms and conditions, you may inform us by contacting us
              at <span className='support-contact'>“Support@loooty.com”.</span>
            </div>
            <span className='privacy_policy-section-title'>
              Termination/Suspension
            </span>
            In the event of any breach of this Terms of Use, we shall have the
            right to suspend or terminate all or any portion of the services to
            you at our discretion or restrict your access to the marketplace. We
            reserve the right to revoke, terminate or suspend any privileges
            associated with accessing the services for any reason (including
            regulatory instruction) or for no reason whatsoever. You agree that
            we will not be liable to you or any third party for any termination
            or suspension of your access to the marketplace.
            <span className='privacy_policy-section-title'>
              Dispute Resolution
            </span>
            This Terms of Use and any disputes arising from or in connection
            with it shall be governed by and construed in accordance with the
            laws of Nigeria. Any dispute, controversy, or claim arising out of
            or relating to this Terms of Use or the use of this digital
            marketplace shall first be resolved amicably through good-faith
            negotiations. Where Parties are unable to resolve their dispute
            through negotiation, either Party may approach a court of competent
            jurisdiction for the resolution of the dispute.
          </div>
        </section>
      </main>
    </div>
  );
};

export default Terms;
