import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RegisterContext } from "../auth/context/registerContext";
import LoginModal from "../auth/loginModal";
import { VscThreeBars } from "react-icons/vsc";
import { IoSettingsSharp } from "react-icons/io5";
// import { RiNotification3Fill } from 'react-icons/ri';
import NavSearchBar from "../search/navSearch";
import { useMediaQuery } from "react-responsive";
import OpenStoreModal from "../auth/openStoreModal";
import Sidebar from "../sidebar/sidebar";
import { useDispatch } from "react-redux";
import { setSelectedSubPage } from "../../redux/reducers/userStateReducer";
import { BsCart4 } from "react-icons/bs";
import { NavBarDropDown } from "./NavBarDropDown";
import NavBarDropdownItem from "./NavBarDropdownItem ";
import { FaSearch } from "react-icons/fa";
import { LoootyLogo } from "../loootyLogo";
import Cookies from "js-cookie";
import SignInModal from "../auth/signInModal";
import SignUpModal from "../auth/signUpModal";
import { getTeamRoleKey } from "../../utils/getUserTeamRoleKey";
import { CartContext } from "../auth/context/cartContext";

const CreatorImg = "/assets/image/creatorDefaultImg.png";
const UserImg = "/assets/image/userDefaultImg.png";
export const sortOptions = [
  {
    name: "Popular",
    value: "popular",
  },
  {
    name: "Recent",
    value: "recent",
  },
  {
    name: "On sale",
    value: "sale",
  },
  {
    name: "Free",
    value: "free",
  },
  // {
  //   name: 'Lower price',
  //   value: 'lower-price',
  // },
  // {
  //   name: 'Higher price',
  //   value: 'higher-price',
  // },
];

export const filterOptions = [
  {
    name: "License",
    value: "All",
    subcategory: ["basic", "commercial"],
  },
  // {
  //   name: 'Rating',
  //   value:'Any',
  //   subcategory: [
  //     'Any', '4 stars', '3 stars', '2 stars', '1 stars',
  //   ]
  // },
];

// const NotificationItem = ({ selected, setSelected, index }) => {
//   return (
//     <div
//       onClick={() => setSelected(index)}
//       className={`landing__notification-item ${
//         selected === index && 'selected'
//       }`}
//     >
//       <div className={`landing__notification-img-container `}>
//         <img src={CatmanImg} className='landing__notification-img' alt='' />
//       </div>
//       <div className='landing__notification-body'>
//         <h3 className='landing__notification-text'>
//           Loooty Your product - Sony Vision S has been published.
//         </h3>
//         <span className='landing__notification-sub'>about a month ago</span>
//       </div>
//     </div>
//   );
// };
// const NotificationDropModal = ({ onMouseEnter, onMouseLeave }) => {
//   const [selected, setSelected] = useState(0);
//   const navigate = useNavigate();
//   return (
//     <section
//       onMouseEnter={onMouseEnter}
//       onMouseLeave={onMouseLeave}
//       className='landing__notification-modal'
//     >
//       <div className='landing__notifier-arrow-up'></div>
//       <div className='landing__notification-body'>
//         <NotificationItem
//           index={0}
//           selected={selected}
//           setSelected={setSelected}
//         />
//         <NotificationItem
//           index={1}
//           selected={selected}
//           setSelected={setSelected}
//         />
//         <NotificationItem
//           index={2}
//           selected={selected}
//           setSelected={setSelected}
//         />
//         <NotificationItem
//           index={3}
//           selected={selected}
//           setSelected={setSelected}
//         />
//       </div>
//       <div className='landing__notification-footer'>
//         <span
//           onClick={() => navigate('/notification')}
//           className='landing__footer-text'
//         >
//           all notifications
//         </span>
//       </div>
//     </section>
//   );
// };

const NavLoggedIn = () => {
  const navigate = useNavigate();
  // const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  let user = {};

  if (typeof window !== "undefined" && Cookies.get("user")) {
    try {
      user = JSON.parse(Cookies.get("user"));
    } catch (error) {
      // Handle the error, e.g., the cookie contains invalid JSON
      console.error("Error parsing 'user' cookie:", error);
    }
  }
  const navigateToMyProfile = () => {
    if (user?.store) navigate(`/user/store`);
    else navigate(`/user/profile`);
  };
  const { cartLength } = useContext(CartContext);
  // const [cart, setCart] = useState([]);

  // useEffect(() => {
  //   const storedCartItems = localStorage.getItem('loootycart');
  //   if (storedCartItems) {
  //     setCart(JSON.parse(storedCartItems));
  //   }
  // }, [cartCount]);
  let teamKey = getTeamRoleKey(user?.team_roles, "store");

  return (
    <ul className="landing__navbar-inner-container-li">
      <li
        onClick={() => {
          navigate(`/cart`);
        }}
        className="landing__navbar-logo-items nav-cart"
      >
        {cartLength > 0 && <p className="cart-number-alert">{cartLength}</p>}
        <BsCart4 />
      </li>
      <li
        onClick={() => {
          dispatch(setSelectedSubPage({ selected: "settings" }));

          navigate(`/user/${"settings"}?subpage=${"settings"}`);
        }}
        className="landing__navbar-logo-items"
      >
        <IoSettingsSharp />
      </li>
      {/* <li className='landing__navbar-logo-items'>
        <RiNotification3Fill onMouseEnter={() => setShowModal(true)} />
        {showModal && (
          <NotificationDropModal
            onMouseLeave={() => {
              setTimeout(() => {
                setShowModal(false);
              }, 500);
            }}
            onMouseEnter={() => setShowModal(true)}
          />
        )}
      </li> */}
      {/* <li
        onClick={() => {
          dispatch(setSelectedSubPage({ selected: 'messages' }));

          navigate(`/user/${'messages'}?subpage=${'messages'}`);
        }}
        className='landing__navbar-logo-items'
      >
        <GrMail />
      </li> */}
      <li className="landing__navbar-profile-container">
        <div
          onClick={() => navigateToMyProfile()}
          className="landing__navbar-profile-img-container"
        >
          <img
            src={user?.picture || (teamKey ? CreatorImg : UserImg)}
            alt=""
            className="landing__navbar-profile-img"
          />
        </div>
        <div
          onClick={() => navigateToMyProfile()}
          className="landing__navbar-user-name"
        >
          {user && user.id ? user.name : "Login"}
        </div>
      </li>
    </ul>
  );
};

const MobileNavLoggedIn = ({ onSearchIconClick, showSearch = true }) => {
  const navigate = useNavigate();

  return (
    <ul className="landing__navbar-inner-container-li">
      <li onClick={onSearchIconClick} className="landing__navbar-logo-items">
        {showSearch && <FaSearch />}
      </li>
      <li
        onClick={() => {
          navigate(`/cart`);
        }}
        className="landing__navbar-logo-items"
      >
        <BsCart4 />
      </li>
    </ul>
  );
};

const NavLoggedOut = () => {
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const navigate = useNavigate();
  const { cartLength } = useContext(CartContext);

  // const [cart, setCart] = useState([]);

  // useEffect(() => {
  //   const storedCartItems = localStorage.getItem('loootycart');
  //   if (storedCartItems) {
  //     setCart(JSON.parse(storedCartItems));
  //   }
  // }, [cartCount]);

  return (
    <ul
      className="landing__navbar-inner-container"
      style={{ position: "absolute", right: "6px" }}
    >
      <li
        onClick={() => {
          navigate(`/cart`);
        }}
        className="landing__navbar-logo-items nav-cart"
      >
        {cartLength > 0 && <p className="cart-number-alert">{cartLength}</p>}
        <BsCart4 />
      </li>
      <li className={"landing__nav-item"}>
        <Link className="landing__nav-link" to={"#"}>
          Help
        </Link>
      </li>

      <li
        onClick={(e) => {
          setShowRegModal({
            ...showRegModal,
            loginDrop: !showRegModal.loginDrop,
          });
          e.stopPropagation();
        }}
        className={"landing__nav-item login"}
      >
        <Link className="landing__nav-link" to={"#"}>
          Sign&nbsp;In
        </Link>
        {showRegModal.loginDrop && (
          <LoginModal onClick={(e) => e.stopPropagation()} />
        )}
      </li>

      {/* <li
        onClick={(e) =>
          setShowRegModal({ ...showRegModal, register: !showRegModal.register })
        }
        className={'landing__nav-item signup'}
      >
        <Link className='landing__nav-link' to={'#'}>
          Sign&nbsp;Up
        </Link>
      </li> */}
    </ul>
  );
};

const NavBar = ({
  search,
  style,
  scrollToSearch,
  shortSearch,
  pageType,
  isComingSoon,
  isHome,
  searchString,
  setSearchString,
  onSearch,
  categoriesArr,
  setActiveSort,
  filterProductsFN,
  categorizeProducts,
  cartCount,
}) => {
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 480 });
  const [yScroll, setYScroll] = useState(0);
  let slideIn = false;
  const [activeFilter, setActiveFilter] = useState("");

  const [filterBy, setFilterBy] = useState("");

  let user = {};

  if (typeof window !== "undefined")
    user = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

  const handleFilterByChange = (string) => {
    if (filterBy.toLowerCase() === string.toLowerCase()) setFilterBy("");
    else setFilterBy(string);
  };

  const clickMobileActiveFilter = (filterTitle) => {
    if (activeFilter.toLowerCase() === filterTitle.toLowerCase()) {
      setActiveFilter("");
    } else setActiveFilter(filterTitle);
  };

  const handleScroll = () => {
    setYScroll(window.scrollY);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      isMounted = false;
    };
  }, []);

  return (
    <>
      <nav
        style={{
          ...style,
          background: "transparent",
          width: `${
            isMobile ? "100%" : setShowRegModal.isSidebarOpen ? "82vw" : "100%"
          }`,
          right: `${setShowRegModal.isSidebarOpen ? "-19%" : "0%"}`,
          position: `${yScroll > 400 ? "fixed" : "sticky"}`,
        }}
        className={isMobile ? "landing__navbar-mobileView" : "landing__navbar"}
      >
        <div
          className="landing__navbar-main-box"
          style={{ background: `${yScroll > 50 ? "#232230" : "transparent"}` }}
        >
          <div
            className="landing__sidebar-toggle"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <VscThreeBars
              className="landing__sidebar-toggler"
              onClick={() =>
                setShowRegModal((old) => ({ ...old, isSidebarOpen: true }))
              }
              style={{ color: "white", fontSize: "2rem" }}
            />

            {!isMobile && (
              <div
                style={{
                  position: "absolute",
                  left: "60px",
                }}
              >
                <NavSearchBar
                  containerStyle={{
                    visibility: search ? "visible" : "hidden",
                    display: search ? "flex" : "none",
                    width: "100%",
                  }}
                  short={shortSearch}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onSearch={onSearch}
                />
              </div>
            )}
          </div>

          <Link
            className="landing__navbar-brand"
            to={!user ? "/" : "/home"}
            style={{
              position: "absolute",
              left: isMobile ? "40%" : "47%",
              color: "#ffffff",
            }}
          >
            <LoootyLogo color={"white"} />
          </Link>

          {isComingSoon && !isMobile && (
            <div className="coming__soon-navbar-request-access desktop-coming__soon-navbar-request-access">
              <button
                onClick={() =>
                  setShowRegModal((old) => ({ ...old, login: true }))
                }
                className="coming__soon-navbar-request-btn"
              >
                <span>Sign in</span>
              </button>
              <button
                onClick={() => {
                  setShowRegModal((old) => ({
                    ...old,
                    isComingSoonOpen: true,
                  }));
                }}
                className="coming__soon-navbar-request-btn orange"
              >
                <span>Join waitlist</span>
              </button>
            </div>
          )}

          {!isComingSoon ? (
            !isMobile ? (
              user && user.id ? (
                <NavLoggedIn cartCount={cartCount} />
              ) : (
                <NavLoggedOut cartCount={cartCount} />
              )
            ) : null
          ) : null}

          {isMobile && user?.id && isHome && (
            <MobileNavLoggedIn onSearchIconClick={() => scrollToSearch(471)} />
          )}
        </div>

        {isHome && (
          <div
            style={{
              display: isMobile && search && isHome ? "flex" : "none",
              backgroundColor: "#15151C",
              flexDirection: "column",
              width: "100%",
              cursor: "pointer",
            }}
            className="navbar-mobile-filter-search-container"
          >
            <div
              className="landing__navbar-mobile-search-box"
              style={{ display: search ? "flex" : "none" }}
            >
              <div style={{ width: "85%" }}>
                <NavSearchBar
                  containerStyle={{
                    visibility: search ? "visible" : "hidden",
                    display: search ? "flex" : "none",
                    width: "100%",
                  }}
                  short={shortSearch}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  onSearch={onSearch}
                />
              </div>

              <div
                onClick={() => scrollToSearch(460)}
                className="reg__close-btn"
                style={{ position: "absolute", right: "15px" }}
              >
                <span className="reg__close-btn-text">X</span>
              </div>
            </div>

            <div
              className="landing__navbar-mobile-filter-box"
              style={{ display: search ? "flex" : "none" }}
            >
              <NavBarDropDown
                title={"CATEGORY"}
                isActive={activeFilter === "category"}
                onClick={() => clickMobileActiveFilter("category")}
              />
              <NavBarDropDown
                title={"SORT"}
                isActive={activeFilter === "sort"}
                onClick={() => clickMobileActiveFilter("sort")}
              />
              <NavBarDropDown
                title={"FILTER"}
                isActive={activeFilter === "filter"}
                onClick={() => clickMobileActiveFilter("filter")}
              />
            </div>

            <div
              style={{
                position: "relative",
                display: search ? "flex" : "none",
              }}
            >
              <div
                className="nav_mobile_filter-options-container"
                style={{ display: search ? "flex" : "none" }}
              >
                {activeFilter.toLocaleLowerCase() === "category"
                  ? categoriesArr?.map((item) => (
                      <NavBarDropdownItem
                        key={item?.name}
                        option={item}
                        onClick={() => categorizeProducts(item?.name)}
                      />
                    ))
                  : activeFilter.toLocaleLowerCase() === "sort"
                  ? sortOptions?.map((item) => (
                      <NavBarDropdownItem
                        key={item?.name}
                        option={item}
                        onClick={() => {
                          setActiveSort(item.value);
                          setActiveFilter("");
                        }}
                      />
                    ))
                  : activeFilter.toLocaleLowerCase() === "filter"
                  ? filterOptions?.map((item) => (
                      <>
                        <NavBarDropdownItem
                          key={item.name}
                          option={item}
                          onClick={() => handleFilterByChange(item.name)}
                          dropDown={true}
                          isActive={
                            filterBy.toLowerCase() === item.name.toLowerCase()
                          }
                        />
                        {filterBy.toLowerCase() === "license" && (
                          <div
                            className="nav_mobile_filter-sub-options-container"
                            style={{ display: search ? "flex" : "none" }}
                          >
                            <>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  setActiveSort("basicPrice");
                                  setActiveFilter("");
                                }}
                              >
                                Basic
                              </div>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  setActiveSort("commercialPrice");
                                  setActiveFilter("");
                                }}
                              >
                                Commercial
                              </div>
                            </>
                          </div>
                        )}
                        {filterBy.toLowerCase() === "rating" && (
                          <div
                            className="nav_mobile_filter-sub-options-container"
                            style={{ display: search ? "flex" : "none" }}
                          >
                            <>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  filterProductsFN(filterBy, 4);
                                }}
                              >
                                4 stars
                              </div>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  filterProductsFN(filterBy, 3);
                                }}
                              >
                                3 stars
                              </div>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  filterProductsFN(filterBy, 2);
                                }}
                              >
                                2 stars
                              </div>
                              <div
                                className="navbar__dropdown-filter-sub-item"
                                onClick={() => {
                                  filterProductsFN(filterBy, 1);
                                }}
                              >
                                1 stars
                              </div>
                            </>
                          </div>
                        )}
                      </>
                    ))
                  : null}
              </div>
            </div>
          </div>
        )}
      </nav>

      {showRegModal.isSidebarOpen && (
        <Sidebar
          isComingSoon={isComingSoon}
          pageType={pageType}
          className={slideIn ? "fade-out-sidebar" : ""}
        />
      )}
      {showRegModal.register && <SignUpModal />}
      {showRegModal.login && <SignInModal />}
      {showRegModal.openStore && <OpenStoreModal />}
    </>
  );
};

export default NavBar;
