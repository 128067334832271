import "./auth.css";
import Cookies from "js-cookie";
import { FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { signInAction } from "../../api/auth";
import { RegisterContext } from "./context/registerContext";
import LoootyLoader from "../loader/loootyLoader";
import { FcGoogle } from "react-icons/fc";

const LoginModal = ({ onClick }) => {
  const [showRegModal, setShowRegModal] = useContext(RegisterContext);

  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const dispatch = useDispatch();
  const [loginInfo, setLoginInfo] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await dispatch(signInAction({ signInData: loginInfo }))
      .unwrap()
      .then(async (result) => {
        setIsLoading(false);
        Cookies.set("user", JSON.stringify(result.data));
        toast.success("Sign in successful", {
          autoClose: 2000,
          className: "toast__message",
        });
        setShowRegModal({ ...showRegModal, login: false });
        navigate("/home");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response) {
          toast.error(err.response.data.message, {
            autoClose: 2000,
            className: "toast__message",
          });
        }
      });
  };
  const web_url = process.env.REACT_APP_WEB_URL || "";
  const googleWebSignIn = () => {
    let data = {
      scope: "openid+profile+email",
      include_granted_scopes: "true",
      response_type: "token",
      state: "state_parameter_passthrough_value",
      redirect_uri: `${web_url}/api/auth/socialite/google/callback`,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    };

    return `https://accounts.google.com/o/oauth2/v2/auth?scope=${data.scope}&include_granted_scopes=${data.include_granted_scopes}&response_type=${data.response_type}&state=${data.state}&redirect_uri=${data.redirect_uri}&client_id=${data.client_id}`;
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className="lgn__login-modal">
      <div className="lgn__arrow"></div>
      <form
        autoComplete="off"
        onSubmit={handleSignIn}
        onClick={(e) => e.stopPropagation()}
        className="lgn__login-form"
      >
        <div className="lgn__form-group">
          <input
            autoComplete="off"
            value={loginInfo.email}
            onChange={(e) =>
              setLoginInfo((old) => ({ ...old, email: e.target.value }))
            }
            className="lgn__form-control"
            placeholder="Email"
          />
        </div>

        <div className="lgn__form-group">
          <input
            autoComplete="off"
            value={loginInfo.password}
            onChange={(e) =>
              setLoginInfo((old) => ({ ...old, password: e.target.value }))
            }
            type={hidePassword ? "password" : "text"}
            className="lgn__form-control"
            placeholder="Password"
          />
          <span
            onClick={() => setHidePassword(!hidePassword)}
            className="lgn__hide-sensitive-info-button"
          >
            {hidePassword ? (
              <BsEyeFill className="lgn__hide-sensitive-ib-icon" />
            ) : (
              <FaEyeSlash className="lgn__hide-sensitive-ib-icon" />
            )}
          </span>
        </div>

        <div className="lgn__form-recovery-container">
          <p>
            {" "}
            Forgot your{" "}
            <span style={{ color: "#FF9700" }}>Password / Username?</span>
          </p>
        </div>

        <div className="lgn__form-btn-group">
          <button onClick={(e) => handleSignIn(e)} className="lgn__login-btn">
            <span className="lgn__login-btn-text">
              {isLoading ? <LoootyLoader /> : "SIGN IN"}
            </span>
          </button>
        </div>

        <div className="lgn__login-alternatives-container">
          <a href={googleWebSignIn()} className="reg__btn-google">
            <FcGoogle style={{ fontSize: "2rem", transform: "skewX(25deg)" }} />
            <span className="reg__btn-google-text">
              Sign&nbsp;In&nbsp;with&nbsp;Google
            </span>
          </a>
          {/* <button className="lgn__btn-facebook">
            <FaFacebookF
              style={{ fontSize: "1.3rem", transform: "skewX(15deg)" }}
            />
            <span className="lgn__btn-facebook-text">
              Sign in with Facebook
            </span>
          </button> */}
          {/* <div className='lgn__form-recovery-container'>
            <p>
              {' '}
              Don't have an account?{' '}
              <span style={{ color: '#FF9700' }}>Sign up! </span>
            </p>
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default LoginModal;
